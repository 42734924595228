<template>
  <div id="floor" ref="floor">
    <div>
      <div
        :class="roomStyle"
        :ref="'room-' + index"
        v-for="(room, index) in currentRooms"
        :key="room.id"
        @click="onClick(index)"
      >
        <p :class="roomTextStyle">{{ room.name }}</p>
        <poi-item
          v-for="poi in poisForRoom(room, index)"
          :key="poi.asset"
          :style="styleForPoi(poi)"
          :asset="assetForPoi(poi)"
          v-model="scale"
          :poi="poi"
          @onPoiItem="onPoiItem"
        />
      </div>
    </div>
    <img src="/assets/svgs/building1_floor0.svg" />
  </div>
</template>

<script>
import PoiItem from "./PoiItem.vue";
export default {
  name: "Floor_0_1",
  components: {
    PoiItem,
  },
  props: ["building", "floor", "displayLevel", "scale"],
  data() {
    return {
      exhibition: this.$root.$data.exhibition,
    };
  },
  computed: {
    roomStyle() {
      if (this.displayLevel === 1) {
        return "room-name room-name-none";
      }
      return "room-name room-name-normal";
    },
    roomTextStyle() {
      return this.displayLevel === 0 ? "level-visible" : "level-invisible";
    },
    currentRooms() {
      return this.exhibition.buildings[this.building].floors[this.floor].rooms;
    },
  },
  methods: {
    onClick(index) {
      const ref = this.$refs["room-" + index][0];
      const rect = {
        x: ref.offsetLeft,
        y: ref.offsetTop,
        width: ref.offsetWidth,
        height: ref.offsetHeight,
      };
      this.$emit("onRoomClick", rect);
    },
    styleForPoi(poi) {
      const left = 100 * poi.position.x;
      const top = 100 * poi.position.y;
      let style = "position: absolute;";
      if (left < 50) style += "left: " + left + "%;";
      else style += "right: " + (100 - left) + "%;";
      if (top < 50) style += "top: " + top + "%;";
      else style += "bottom: " + (100 - top) + "%;";
      return style;
    },
    poisForRoom(room, roomIndex) {
      if (this.displayLevel === 0) return;
      const pois = [];
      room.pois.forEach((poi, poiIndex) => {
        poi.room = roomIndex;
        poi.index = poiIndex;
        pois.push(poi);
      });
      return pois;
    },
    assetForPoi(poi) {
      return this.exhibition.getAssetForPoi(poi);
    },
    onPoiItem(poi) {
      this.$emit("onPoiItem", poi);
    },
  },
};
</script>

<style scoped src="../css/floor_1_0.css">
</style>
